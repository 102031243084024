import useAxios from "axios-hooks";
import Error from "components/error";
import Loading from "components/loading";
import { useEffect, useRef, useState } from "react";
import { BsStarHalf, BsTrash } from "react-icons/bs";
import { IoDocuments } from "react-icons/io5";
import { MdFileUpload } from "react-icons/md";
import InputField from "./ProjectTextInput";
import StatusShow from "components/StatusShow";
import HigherOffDropdown from "components/dropdown/HigherOffDropdown";
import toast from "react-hot-toast";
import DateFields from "components/fields/DateFields";
import { checkDate } from "helpers/utils";

function ProjectSidebar({ handleCreateProject, loading, error, project }) {
  const hiddenFileInput = useRef(null);

  const [{ data: higherOfficals }] = useAxios(
    {
      url: "/account/higher-official-users/",
      method: "GET",
    },
    { useCache: false }
  );
  const [{ data: teamLeaders }] = useAxios(
    {
      url: "/account/team-leader-users/",
      method: "GET",
      params: {
        no_pagination: true,
      },
    },
    { useCache: false }
  );
  const [{ data: departments }] = useAxios(
    {
      url: "/account/departments/",
      method: "GET",
      params: {
        no_pagination: true,
      },
    },
    { useCache: false }
  );

  const [owner, setOwner] = useState("");
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [description, setDescription] = useState("");
  const [projectPriority, setProjectPriority] = useState("high");
  const [startDate, setStartDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [endDate, setEndDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [cost, setCost] = useState("");
  const [resources, setResources] = useState("");
  const [objective, setObjective] = useState("");
  const [goal, setGoal] = useState("");
  const [beneficiaries, setBeneficiaries] = useState("");
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState("not_started");
  const [teamLeader, setTeamLeader] = useState("");
  const [currency, setCurrency] = useState("ETB");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!teamLeader) {
      return toast.error("Team Leader is required!!", {
        duration: 4000,
        position: "top-right",
      });
    }

    if (!checkDate(startDate, endDate)) {
      window.document.getElementById("dateField").scrollIntoView();
      return;
    }
    const formData = new FormData();
    const formFiles = [...files];

    formFiles.forEach((file) => {
      formData.append("files", file);
    });

    formData.append("project_name", name);
    formData.append("project_short_name", shortName);
    formData.append("project_description", description);
    formData.append(
      "start_date",
      `${startDate.year}-${startDate.month}-${startDate.day}`
    );
    formData.append(
      "end_date",
      `${endDate.year}-${endDate.month}-${endDate.day}`
    );
    formData.append("project_cost", cost);
    formData.append("project_objective", objective);
    formData.append("project_beneficiaries", beneficiaries);
    formData.append("status", status);
    formData.append("project_priority", projectPriority);
    formData.append("owned_by", owner?.profile?.user || "");
    formData.append("project_goal", goal);
    formData.append("resources", resources);
    formData.append("assigned_team_leader", teamLeader?.profile?.user || "");
    formData.append("currency", currency);
    formData.append("responsible_department", selectedDepartment);

    handleCreateProject(formData);
  };

  useEffect(() => {
    if (project !== null) {
      setName(project.project_name || "");
      setShortName(project.project_short_name || "");
      setDescription(project.project_description || "");
      setStartDate(
        project?.start_date
          ? {
              day: project?.start_date.substring(8, 10),
              month: project?.start_date.substring(5, 7),
              year: project?.start_date.substring(0, 4),
            }
          : { day: "", month: "", year: "" }
      );
      setEndDate(
        project?.end_date
          ? {
              day: project?.end_date.substring(8, 10),
              month: project?.end_date.substring(5, 7),
              year: project?.end_date.substring(0, 4),
            }
          : { day: "", month: "", year: "" }
      );
      setCost(project.project_cost || "");
      setObjective(project.project_objective || "");
      setBeneficiaries(project.project_beneficiaries || "");
      setProjectPriority(project.project_priority || "");
      setStatus(project.status);
      setResources(project.resources || "");
      setGoal(project.project_goal || "");
      if (project.owned_by && higherOfficals) {
        setOwner(
          project.owned_by
            ? higherOfficals?.find((x) => x.profile?.user === project.owned_by)
            : {}
        );
      }
      if (project.assigned_team_leader && teamLeaders) {
        setTeamLeader(
          project.assigned_team_leader
            ? teamLeaders?.find(
                (x) => x.profile?.user === project.assigned_team_leader
              )
            : {}
        );
      }
      if (project.responsible_department && departments) {
        setSelectedDepartment(
          departments?.find((x) => x.id === project.responsible_department)?.id
        );
      }
    }
    return () => {
      setName("");
      setShortName("");
      setDescription("");
      setStartDate("");
      setEndDate("");
      setCost("");
      setObjective("");
      setBeneficiaries("");
      setProjectPriority("high");
      setStatus("not_started");
      setResources("");
      setGoal("");
      setOwner("");
    };
  }, [project, higherOfficals, teamLeaders, departments]);

  return (
    <div className="mt-12 w-full px-5 lg:mx-5 lg:mt-0 lg:min-w-[55dvw] lg:p-10 3xl:min-w-[48dvw]">
      <div className="mt-10 mb-5 flex items-center justify-between">
        <h1 className="font-poppins text-2xl font-bold text-[#707070] dark:text-gray-400 lg:text-3xl">
          Create Task
        </h1>
        <button
          className="flex h-fit items-center gap-2 rounded-lg bg-[#485FE5] px-10 py-2 font-poppins text-white disabled:opacity-50"
          type="submit"
          form="productCreateForm"
          disabled={loading}
        >
          {project ? "Update" : "Create"}
        </button>
      </div>

      {error && <Error error={error} />}

      {!error && loading ? (
        <Loading />
      ) : (
        <>
          <div className="mt-16 text-[#707070] ">
            <div className="flex justify-between lg:justify-start">
              <div className="flex min-w-fit gap-2 fill-[#E4CCFD]">
                <BsStarHalf color="#D10202" />
                <p>Status</p>
              </div>

              <div className="lg:ml-[27%]">
                <StatusShow status={status} extra={"bg-[#FAFAFA] px-4 !pb-0"} />
              </div>
            </div>
          </div>

          <form
            id="productCreateForm"
            className="mt-8 mb-5 w-full text-[#707070]"
            onSubmit={handleSubmit}
          >
            <InputField
              title="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={"Task name"}
              required={true}
            />
            <InputField
              title="Short Name"
              value={shortName}
              onChange={(e) => setShortName(e.target.value)}
              placeholder={"Task short name"}
              required={false}
            />

            <div className="mt-4 flex justify-between">
              <p className="flex-grow lg:flex-grow-0">Owned By</p>
              <HigherOffDropdown
                id="ownedBy"
                higherOfficals={higherOfficals}
                onClick={(item) => setOwner(item)}
                value={owner}
              />
            </div>
            {/*  */}
            <div className="mt-4 flex justify-between">
              <p className="flex-grow lg:flex-grow-0">
                Team Leader <span className="text-red-700">*</span>
              </p>
              <HigherOffDropdown
                id="teamLeader"
                title={"Select Team Leader"}
                higherOfficals={teamLeaders || []}
                onClick={(item) => setTeamLeader(item)}
                value={teamLeader}
              />
            </div>
            {/*  */}
            <div className="mt-4 flex justify-between">
              <p className="flex-grow lg:flex-grow-0">Committee</p>
              <select
                className="rounded-lg bg-[#FAFAFA] p-4 lg:w-[64%] lg:flex-grow-0"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                required
              >
                {departments?.map((department, index) => (
                  <option value={department.id} key={index}>
                    {department.department_name}
                  </option>
                ))}
              </select>
            </div>

            <InputField
              title="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={"Task description"}
              required={false}
              textArea={true}
            />

            <div className="mt-4 flex justify-between">
              <p className="flex-grow  lg:flex-grow-0">
                Priority <span className="text-red-700">*</span>
              </p>
              <select
                className="rounded-lg bg-[#FAFAFA] p-4 lg:w-[64%]"
                onChange={(e) => setProjectPriority(e.target.value)}
                value={projectPriority}
              >
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </select>
            </div>
            {/* Dates */}
            <div className="mt-4 flex justify-between" id="dateField">
              <p>
                Start Date <span className="text-red-700">*</span>
              </p>
              <DateFields
                value={startDate}
                setValue={setStartDate}
                width={"64%"}
              />
            </div>
            <div className="mt-4 flex justify-between">
              <p>
                End Date <span className="text-red-700">*</span>
              </p>
              <div className="lg:w-[64%]">
                <DateFields
                  value={endDate}
                  setValue={setEndDate}
                  width={"100%"}
                />
                {!checkDate(startDate, endDate) && (
                  <span className="text-sm text-red-500">
                    End date cannot be less than Start date
                  </span>
                )}
              </div>
            </div>

            {/*  */}
            <div className="mt-4 w-full justify-between lg:flex">
              <p className="lg:w-[56%]">Budget</p>
              <div className="flex w-full flex-grow items-center gap-2">
                <div
                  className="relative w-fit rounded-lg bg-[#FAFAFA] pr-3"
                  onClick={() => {
                    document.getElementById("currency").focus();
                  }}
                >
                  <select
                    id="currency"
                    className="mr-2 appearance-none rounded-lg bg-[#FAFAFA] p-4 outline-none focus:outline-none"
                    onChange={(e) => setCurrency(e.target.value)}
                    value={currency}
                  >
                    <option value="ETB">ETB</option>
                    <option value="Dollar">USD</option>
                    <option value="Euro">EUR</option>
                    <option value="Pound">GBP</option>
                    <option value="Real">BRL</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center px-2">
                    <svg
                      className="h-4 w-4 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                    </svg>
                  </div>
                </div>
                <input
                  id="amount"
                  type="number"
                  placeholder="Task Budget"
                  className="w-full rounded-lg bg-[#FAFAFA] p-4"
                  min="0"
                  step="0.01"
                  value={cost}
                  onChange={(e) => setCost(e.target.value)}
                />
              </div>
            </div>
            <InputField
              title="Resources"
              value={resources}
              onChange={(e) => setResources(e.target.value)}
              placeholder={"Task resources"}
              required={false}
              type="text"
            />
            <InputField
              title="Objective"
              value={objective}
              onChange={(e) => setObjective(e.target.value)}
              placeholder={"Task objective"}
              required={true}
              textArea={true}
              rows={2}
            />
            <InputField
              title="Goal"
              value={goal}
              onChange={(e) => setGoal(e.target.value)}
              placeholder={"Task goal"}
              required={false}
              textArea={true}
              rows={2}
            />
            <InputField
              title="Beneficiaries"
              value={beneficiaries}
              onChange={(e) => setBeneficiaries(e.target.value)}
              placeholder={"project beneficiaries"}
              required={false}
              textArea={true}
              rows={2}
            />

            <div className="mt-4 justify-between lg:flex">
              <p className="mb-2">Upload Files</p>
              <input
                type="file"
                ref={hiddenFileInput}
                className="hidden"
                hidden
                multiple
                onChange={(e) => setFiles(e.target.files)}
              />
              {[...files].length > 0 ? (
                <div className="flex h-full w-full flex-col items-center rounded-xl border-[2px] border-dashed border-gray-200 bg-[#FAFAFA] py-3 dark:!border-navy-700 lg:w-[64%] lg:pb-0">
                  {[...files].map((file, index) => (
                    <div
                      key={index}
                      className="mb-1 flex w-full items-center justify-between gap-10 px-4"
                    >
                      <div className="flex items-center gap-1 ">
                        <IoDocuments size={25} className="" />
                        <p className="py-2 text-xl">
                          {file.name.length > 20
                            ? file.name.slice(0, 20) + "..."
                            : file.name}
                        </p>
                      </div>
                      <div
                        className="cursor-pointer py-3"
                        onClick={() => {
                          setFiles((prev) => {
                            const newFiles = [...prev];
                            newFiles.splice(index, 1);
                            return newFiles;
                          });
                          if ([...files].length === 0) {
                            hiddenFileInput.current.value = null;
                          }
                        }}
                      >
                        <BsTrash color="red" size={20} />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className="flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 bg-[#FAFAFA] py-3 dark:!border-navy-700 lg:w-[64%] lg:pb-0"
                  onClick={() => hiddenFileInput.current.click()}
                >
                  <MdFileUpload className="text-[80px] text-gray-700 dark:text-white" />
                  <h4 className="text-xl font-bold text-gray-700 dark:text-white">
                    Upload Files
                  </h4>
                  <p className="mt-2 pb-2 text-sm font-medium text-gray-600">
                    PDF, DOCX and XLSX files are allowed
                  </p>
                </div>
              )}
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default ProjectSidebar;
