import { statusOptions } from "components/dropdown/StatusDropdown";
import StatusDropdownDeliverable from "components/dropdown/StatusDropdownDeliverable";
import DelViewModal from "components/popover/DelViewModal";
import { reverseDateString } from "helpers/utils";
import { useRef, useState } from "react";
import { FaChevronDown, FaTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import DeleteModal from "views/admin/expert/components/DeleteModal";

function SubDeliverableCard({
  deliverable,
  setSelectedDel,
  isExpert,
  handleStatusUpdate,
  handleDelDelete,
}) {
  const [viewDel, setViewDel] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const parentRef = useRef(null);

  return (
    <>
      {viewDel && (
        <DelViewModal
          id={"deliverable" + deliverable.id}
          data={deliverable}
          setOpen={setViewDel}
        />
      )}
      <DeleteModal
        id={"deliverable_progress" + deliverable.id}
        handleConfirm={() => {
          handleDelDelete(deliverable.id);
          window.document
            .getElementById("deliverable_progress" + deliverable.id)
            .classList.replace("flex", "hidden");
        }}
        handleCancel={() => {
          window.document
            .getElementById("deliverable_progress" + deliverable.id)
            .classList.replace("flex", "hidden");
        }}
      />
      <div
        key={deliverable.id}
        className="flex max-w-[220px] flex-col items-center gap-2 rounded-xl bg-white p-5 text-center shadow-xl dark:bg-navy-900"
      >
        <div
          className={`mb-5 flex items-start ${
            isExpert ? "justify-between" : "justify-center"
          } w-full gap-5`}
        >
          <h1
            className="max-w-[200px] cursor-pointer text-lg font-semibold hover:text-blueSecondary"
            onClick={() => setViewDel(true)}
            onTouchStart={() => setViewDel(true)}
          >
            {deliverable.deliverable_name?.length > 14
              ? deliverable.deliverable_name.slice(0, 14) + "..."
              : deliverable.deliverable_name}
          </h1>

          <div className="flex items-center gap-3">
            {isExpert && deliverable.status !== "completed" && (
              <FiEdit
                size={16}
                className="mt-2 cursor-pointer hover:stroke-blueSecondary"
                onClick={() => setSelectedDel(deliverable)}
              />
            )}
            {isExpert && (
              <FaTrashAlt
                size={16}
                className="mt-2 cursor-pointer hover:text-red-500"
                onClick={() => {
                  window.document
                    .getElementById("deliverable_progress" + deliverable.id)
                    .classList.replace("hidden", "flex");
                }}
              />
            )}
          </div>
        </div>

        <p className="w-40 rounded-2xl bg-[#deecfa] py-1 text-navy-900 dark:bg-navy-900 dark:text-white">
          {deliverable.start_date?.length > 10
            ? reverseDateString(deliverable.start_date.slice(0, 10))
            : reverseDateString(deliverable.start_date)}
        </p>
        <p className="w-40 rounded-2xl bg-[#deecfa] py-1 text-navy-900 dark:bg-navy-900 dark:text-white">
          {deliverable.end_date?.length > 10
            ? reverseDateString(deliverable.end_date.slice(0, 10))
            : reverseDateString(deliverable.end_date)}
        </p>

        <div className="parent mt-3 w-full" ref={parentRef}>
          <div
            className={`flex items-center gap-2 ${
              isExpert && "cursor-pointer"
            }`}
            onClick={() => (isExpert ? setIsOpen(!isOpen) : null)}
          >
            <div className="w-full rounded-full">
              <div
                className="flex items-center gap-2 rounded-lg py-0.5 px-3"
                style={{
                  background:
                    statusOptions.find(
                      (item) => item.title === deliverable.status
                    ).color + "40",
                }}
              >
                <div
                  className="h-2 w-2 rounded-sm "
                  style={{
                    background: statusOptions.find(
                      (item) => item.title === deliverable.status
                    ).color,
                  }}
                />
                <p
                  className="text-base"
                  style={{
                    color: statusOptions.find(
                      (item) => item.title === deliverable.status
                    ).color,
                  }}
                >
                  {deliverable.status.toUpperCase()}
                </p>
              </div>
            </div>
            {isExpert && deliverable.status !== "completed" && (
              <FaChevronDown size={10} />
            )}
          </div>
          {isExpert && deliverable.status !== "completed" && (
            <StatusDropdownDeliverable
              isOpen={isOpen}
              parentRef={parentRef}
              setIsOpen={setIsOpen}
              data={deliverable}
              onSubmit={handleStatusUpdate}
            />
          )}
        </div>
      </div>
    </>
  );
}
export default SubDeliverableCard;
