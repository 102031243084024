/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Error from "components/error";
import Loading from "components/loading";
import { useParams } from "react-router-dom";
import SubDeliverableCard from "views/admin/ceo/components/SubDeliverableCard";
import ListsLayout from "views/admin/ceo/components/ListsLayout";
import SearchIcon from "components/icons/SearchIcon";
import DeliverableModalForm from "views/admin/expert/components/DeliverableModalForm";
import { IoCheckmark } from "react-icons/io5";
import toast from "react-hot-toast";

function DeliverableDetails() {
  const path = window.location.pathname;
  const params = useParams();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedDel, setSelectedDel] = useState();
  const [error, setError] = useState();

  const [{ data: delData, loading: delLoad, error: delErr }, getDeliverable] =
    useAxios(
      {
        url: `/main_app/deliverables/${params.delId}/`,
        params: { no_pagination: true },
        method: "GET",
      },
      { manual: true }
    );

  const [{ loading: delPostLoad, error: delPostErr }, postSubDel] = useAxios(
    {
      url: "/main_app/activities/",
      method: "POST",
    },
    { manual: true }
  );

  const [, updateStatus] = useAxios(
    {
      url: `/main_app/deliverables/`,
      method: "PUT",
    },
    { manual: true }
  );

  const handleStatusUpdate = (data, value) => {
    if (value.status === "completed") {
      const formData = new FormData();
      formData.append("status", value.status);
      formData.append("actual_target", value.target);
      formData.append("actual_start_date", value.startDate);
      formData.append("actual_end_date", value.endDate);
      value.file && formData.append("completion_file", value.file);
      formData.append("status_memo", value.status_memo);

      updateStatus({
        data: formData,
        url: `/main_app/deliverables-status-update/${data.id}/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
        getDeliverable();
      });
    } else {
      updateStatus({
        data: { ...data, ...value },
        url: `/main_app/deliverables/${data.id}/`,
        method: "PUT",
      }).then(() => {
        getDeliverable();
      });
    }
  };

  const handleDeliverableSave = async (data) => {
    if (!delData.activity) return;
    const deliverable = {
      deliverable_name: data.deliverableName,
      deliverable_description: data.deliverableDescription,
      target: data.target,
      measurement_unit: data.measurementUnit,
      start_date: data.startDate,
      end_date: data.endDate,
      status: data.status,
      activity: delData.activity,
      parent_deliverable: delData.id,
    };
    try {
      postSubDel({
        url: selectedDel
          ? `/main_app/deliverables/${selectedDel.id}/`
          : "/main_app/deliverables/",
        method: selectedDel ? "PUT" : "POST",
        data: deliverable,
      }).then(async () => {
        toast("Deliverable saved successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
          duration: 3000,
        });
        getDeliverable();
        setShowModal(false);
      });
    } catch (error) {
      console.log(error);
      setError(delPostErr);
    }
  };

  const handleDelete = async (id) => {
    try {
      await updateStatus({
        url: `/main_app/deliverables/${id}/`,
        method: "DELETE",
      });
      getDeliverable();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedDel) {
      setShowModal(true);
      setError(null);
    }
  }, [selectedDel]);

  useEffect(() => {
    if (params.id) {
      getDeliverable();
    }
  }, [params.id]);

  return (
    <ListsLayout
      title={delData?.deliverable_name}
      breadcrumb={`/tasks/milestones/activities/deliverables/sub-deliverables`}
    >
      {delErr && <Error error={delErr} />}
      {delLoad && <Loading />}
      {!delErr && !delLoad && (
        <div className="mt-5 flex-grow rounded-lg bg-[#F2F9FFCC] py-8 px-4 dark:bg-navy-700">
          {/* Deliverables */}
          <div className="mb-5 flex items-start justify-between ">
            <h1 className="text-2xl font-semibold text-black-900">
              Sub Deliverable Progress
            </h1>
            <div className="mt-2 flex flex-col items-end justify-end gap-2 md:flex-row md:items-center md:gap-0">
              <div className="flex items-center rounded-l-md bg-white dark:bg-navy-900">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className=" w-full py-1.5 px-4 focus:outline-none dark:bg-navy-900 dark:text-white dark:placeholder-gray-400"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              {path.includes("expert") && (
                <>
                  {showModal && (
                    <DeliverableModalForm
                      title={"Progress"}
                      data={selectedDel}
                      edit={selectedDel}
                      setEdit={setSelectedDel}
                      setOpen={(bool) => {
                        setSelectedDel(null);
                        setShowModal(bool);
                      }}
                      error={error}
                      handleSave={handleDeliverableSave}
                      loading={delPostLoad}
                    />
                  )}
                  <button
                    className="rounded bg-[#485FE5] px-4 py-1.5 text-white md:rounded-none md:rounded-r-md"
                    onClick={() => {
                      setSelectedDel(null);
                      setError(null);
                      setShowModal(true);
                    }}
                  >
                    Add Progress
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="flex w-full flex-wrap gap-5 lg:px-5 3xl:px-10">
            {delData?.child_deliverables?.length === 0 && (
              <p className="text-lg text-gray-400">No progress found</p>
            )}
            {delData?.child_deliverables?.map((deliverable, i) => (
              <SubDeliverableCard
                key={deliverable.id + i}
                deliverable={deliverable}
                setSelectedDel={setSelectedDel}
                isExpert={path.includes("expert")}
                handleStatusUpdate={(value) =>
                  handleStatusUpdate(deliverable, value)
                }
                handleDelDelete={handleDelete}
              />
            ))}
          </div>
        </div>
      )}
    </ListsLayout>
  );
}

export default DeliverableDetails;
