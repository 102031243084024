import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/dropdown/ShadcnDropdown";
import { useEffect, useState } from "react";
import { statusOptions } from "components/dropdown/StatusDropdown";
import DateFields from "components/fields/DateFields";
import { checkDate } from "helpers/utils";

function StatusDropdownDeliverable({
  onSubmit,
  status,
  isOpen,
  setIsOpen,
  data,
}) {
  const [selectedStatus, setSelectedStatus] = useState("not_started");
  const [startDate, setStartDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [endDate, setEndDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [target, setTarget] = useState("");
  const [file, setFile] = useState(null);
  const [statusMemo, setStatusMemo] = useState("");
  const formId = `status-form-${Math.random() * 100000000000}`;

  const handleCompleteSubmit = (e) => {
    e.preventDefault();
    const onSubmitData = {
      status: selectedStatus,
      startDate: `${startDate.year}-${startDate.month}-${startDate.day}`,
      endDate: `${endDate.year}-${endDate.month}-${endDate.day}`,
      target,
      file,
      status_memo: statusMemo ? statusMemo : "",
    };
    if (checkDate(startDate, endDate)) {
      setIsOpen(false);
      onSubmit(onSubmitData);
    }
  };

  const handleStatusChange = (value) => {
    setIsOpen(false);
    onSubmit({
      status: value,
      status_memo: statusMemo ? statusMemo : null,
    });
  };

  const clearForm = () => {
    setStartDate("");
    setEndDate("");
    setTarget("");
    setFile(null);
    setSelectedStatus("not_started");
    setStatusMemo("");
  };

  useEffect(() => {
    if (status) {
      setSelectedStatus(status);
    }
    return () => {
      clearForm();
    };
  }, [status]);

  const getDataEndDate = (data) => {
    if (data) {
      const date = data?.end_date.split("-");
      return {
        day: date[2],
        month: date[1],
        year: date[0],
      };
    }
    return {
      day: "",
      month: "",
      year: "",
    };
  };

  // console.log("Actual date check", checkDate(endDate, getDataEndDate(data)));
  // console.log(getDataEndDate(data));

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger
        className={`absolute  outline-none focus:outline-none`}
      />
      <DropdownMenuContent>
        <div className="flex min-w-[250px] gap-8 rounded-t-xl bg-white p-4 text-sm">
          <div className="flex-grow">
            <h1 className="mb-3 text-start font-semibold">Change status</h1>
            <ul className="flex flex-col gap-1">
              {statusOptions.map((status, index) => (
                <li
                  key={index}
                  className={`cursor-pointer ${
                    selectedStatus === status.title
                      ? "flex w-full items-center justify-between rounded-lg bg-[#5E5ADB] px-2 text-white"
                      : "hover:bg-gray-50"
                  }`}
                  onClick={() => setSelectedStatus(status.title)}
                >
                  <div className="my-2 flex  items-center gap-2 ">
                    <div
                      className={`ml-1 h-3 w-3 rounded-sm `}
                      style={{ background: status.color }}
                    />
                    <p className="mr-2">{status.title.toUpperCase()}</p>
                  </div>
                </li>
              ))}
            </ul>
            {selectedStatus !== "completed" && (
              <div className="mt-4 flex w-full flex-grow flex-col text-start">
                <div>
                  <h1>Memo:</h1>
                  <p className="text-xs text-gray-500">Type Status Memo</p>
                </div>
                <input
                  id="statusMemo"
                  type="text"
                  placeholder="Status Memo"
                  className="mt-1 rounded-lg bg-gray-50 py-4 px-2"
                  onChange={(e) => setStatusMemo(e.target.value)}
                  required
                  value={statusMemo}
                />
              </div>
            )}
          </div>
          {selectedStatus === "completed" && (
            <form
              id={formId}
              className="mb-2 text-start"
              onSubmit={handleCompleteSubmit}
            >
              <div className="mt-4 flex flex-grow items-center justify-between">
                <div className="flex-grow">
                  <h1>
                    Actual Start Date <span className="text-red-700">*</span>
                  </h1>
                  <p className="text-xs text-gray-500">Type Date</p>
                </div>
                <DateFields
                  value={startDate}
                  setValue={setStartDate}
                  width={230}
                />
              </div>
              <div className="flex flex-grow flex-col">
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div className="flex-grow">
                    <h1>
                      Actual End Date <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Type Date</p>
                  </div>
                  <DateFields
                    value={endDate}
                    setValue={setEndDate}
                    width={230}
                  />
                </div>
                {!checkDate(startDate, endDate) && (
                  <span className="mt-1 self-end text-sm text-red-500">
                    End date cannot be less than Start date
                  </span>
                )}
                {!checkDate(getDataEndDate(data), endDate) && (
                  <span className="mt-1 self-end text-sm text-red-500">
                    Actual date cannot be less than Planned date
                  </span>
                )}
              </div>
              <div className="mt-4 flex flex-grow items-center justify-between">
                <div className="flex-grow">
                  <h1>Achieved Target</h1>
                  <p className="text-xs text-gray-500">Type Actual Target</p>
                </div>
                <input
                  id="statusBudget"
                  type="text"
                  placeholder="Achieved Target"
                  className="flex-grow rounded-lg bg-gray-50 py-4 px-2"
                  onChange={(e) => setTarget(e.target.value)}
                  value={target}
                />
              </div>
              <div className="mt-4 flex flex-grow items-center justify-between">
                <div className="flex-grow">
                  <h1>Memo:</h1>
                  <p className="text-xs text-gray-500">Type Status Memo</p>
                </div>
                <input
                  id="statusMemo"
                  type="text"
                  placeholder="Status Memo"
                  className="mt-1 flex-grow rounded-lg bg-gray-50 py-4 px-2"
                  onChange={(e) => setStatusMemo(e.target.value)}
                  value={statusMemo}
                />
              </div>
              <div className="mt-4 flex flex-grow items-center justify-between">
                <div className="flex-grow">
                  <h1>File</h1>
                  <p className="text-xs text-gray-500">Type File</p>
                </div>
                <input
                  id="statusFile"
                  type="file"
                  placeholder="File"
                  className="w-fit max-w-[58%] rounded-lg bg-gray-50 py-4 px-2"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
              {/*  */}
              <div className="mt-5 flex w-full items-center justify-end gap-2 self-end rounded-b-md bg-white pr-2">
                <div
                  className="cursor-pointer rounded-lg p-2 px-4 hover:bg-gray-50 dark:text-gray-800"
                  onClick={() => {
                    clearForm();
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </div>
                <button
                  className="cursor-pointer rounded-lg p-2 px-6 text-[#5E5ADB] hover:bg-navy-50"
                  type="submit"
                >
                  Apply
                </button>
              </div>
            </form>
          )}
        </div>

        {selectedStatus !== "completed" && (
          <div className="flex w-full items-center justify-end gap-2 self-end rounded-b-md bg-white pr-2 pb-2">
            <div
              className="cursor-pointer rounded-lg p-2 px-4 hover:bg-gray-50 dark:text-gray-800"
              onClick={() => {
                clearForm();
                setIsOpen(false);
              }}
            >
              Cancel
            </div>
            <div
              className="cursor-pointer rounded-lg p-2 px-6 text-[#5E5ADB] hover:bg-navy-50"
              onClick={() => {
                setIsOpen(false);
                handleStatusChange(selectedStatus);
                clearForm();
              }}
            >
              Apply
            </div>
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
export default StatusDropdownDeliverable;
