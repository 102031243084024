import { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { statusOptions } from "components/dropdown/StatusDropdown";
import DeleteModal from "views/admin/expert/components/DeleteModal";
import ChevronDownOutline from "components/icons/ChevronDownOutline";
import ChevronRightOutline from "components/icons/ChevronRightOutline";
import DeliverableTableRow from "views/admin/tables/components/DeliverableTableRow";
import useAxios from "axios-hooks";
import toast from "react-hot-toast";
import { IoCheckmark } from "react-icons/io5";
import DeliverableModalForm from "views/admin/expert/components/DeliverableModalForm";
import DelViewModal from "components/popover/DelViewModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/dropdown/ShadcnDropdown";
import { reverseDateString } from "helpers/utils";

export default function ActivityTableRow({
  row,
  rowProps,
  setSelected,
  handleDelete,
  handleDelDelete,
  isExpert,
  link,
  index,
  refetch,
}) {
  const [showChild, setShowChild] = useState(false);
  const [view, setView] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDeliverable, setSelectedDeliverable] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const parentRef = useRef(null);

  const [{ loading: delPostLoad, error: delPostErr }, postDel] = useAxios(
    {
      url: "/main_app/deliverables/",
      method: "POST",
    },
    { manual: true }
  );

  const [, updateStatus] = useAxios(
    {
      url: `/main_app/deliverables/`,
      method: "PUT",
    },
    { manual: true }
  );

  const handleStatusUpdate = (data, value) => {
    if (value.status === "completed") {
      const formData = new FormData();
      formData.append("status", value.status);
      formData.append("actual_target", value.target);
      formData.append("actual_start_date", value.startDate);
      formData.append("actual_end_date", value.endDate);
      value.file && formData.append("completion_file", value.file);
      formData.append("status_memo", value.status_memo);

      updateStatus({
        data: formData,
        url: `/main_app/deliverables-status-update/${data.id}/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          toast("Status updated successfully", {
            icon: <IoCheckmark />,
            style: { color: "green" },
          });
          refetch();
        })
        .catch((error) => {
          console.error(error);
          toast("Failed to update status", {
            icon: <IoCheckmark />,
            style: { color: "red" },
          });
        });
    } else {
      updateStatus({
        data: { ...data, ...value },
        url: `/main_app/deliverables/${data.id}/`,
        method: "PUT",
      })
        .then(() => {
          toast("Status updated successfully", {
            icon: <IoCheckmark />,
            style: { color: "green" },
          });
          refetch();
        })
        .catch((error) => {
          console.error(error);
          toast("Failed to update status", {
            icon: <IoCheckmark />,
            style: { color: "red" },
          });
        });
    }
  };

  const hadnleDeliverableSave = async (data) => {
    if (!row.original.id) return;
    const deliverable = {
      deliverable_name: data.deliverableName,
      deliverable_description: data.deliverableDescription,
      target: data.target,
      measurement_unit: data.measurementUnit,
      start_date: data.startDate,
      end_date: data.endDate,
      status: data.status,
      activity: row.original.activity,
      parent_deliverable: row.original.id,
    };
    try {
      postDel({
        url: selectedDeliverable
          ? `/main_app/deliverables/${selectedDeliverable.id}/`
          : "/main_app/deliverables/",
        method: selectedDeliverable ? "PUT" : "POST",
        data: deliverable,
      }).then(async () => {
        toast("Sub Deliverable saved successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
          duration: 3000,
        });
        refetch();
        setShowModal(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedDeliverable) {
      setShowModal(true);
    }
  }, [selectedDeliverable]);

  const subDeliverables = row?.original?.child_deliverables?.filter(
    (item) => item.parent_deliverable !== null
  );

  return (
    <>
      <tr
        {...rowProps}
        className={`
      ${showChild ? "bg-[#EDEDFC] dark:bg-navy-900 " : ""}
    `}
      >
        <td
          className="border-b"
          style={{
            width: "50px", // Apply custom width
            minWidth: "50px", // Apply min width
            maxWidth: "50px", // Apply max width
          }}
        >
          <div className="ml-4 w-fit ">
            {showChild ? (
              <ChevronDownOutline
                onClick={() => setShowChild(!showChild)}
                className="cursor-pointer fill-[#171C26] dark:fill-white"
              />
            ) : (
              <ChevronRightOutline
                onClick={() => setShowChild(!showChild)}
                className="cursor-pointer fill-[#171C26] dark:fill-white"
              />
            )}
          </div>
        </td>
        {row.cells.map((cell, i) => {
          let data = "";
          if (cell.column.Header === "NO") {
            data = (
              <p className="pl-3 text-sm text-navy-900 dark:text-white">
                {Number(index + 1).toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}
              </p>
            );
          } else if (cell.column.Header === "Name") {
            data = (
              <p className="max-w-[250px] truncate pl-3 text-sm text-navy-900 dark:text-white 2xl:max-w-[500px]">
                {cell.value}
              </p>
            );
          } else if (cell.column.Header === "Status") {
            data = (
              <div className="parent w-fit pr-2" ref={parentRef}>
                <div
                  className={`flex items-center gap-1`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div className="w-fit rounded-full">
                    <div
                      className="flex items-center gap-2 rounded-lg py-0.5 px-3"
                      style={{
                        background:
                          statusOptions.find(
                            (item) => item.title === cell.value
                          ).color + "40",
                      }}
                    >
                      <div
                        className="h-2 w-2 rounded-sm"
                        style={{
                          background: statusOptions.find(
                            (item) => item.title === cell.value
                          ).color,
                        }}
                      />
                      <p
                        className="text-sm"
                        style={{
                          color: statusOptions.find(
                            (item) => item.title === cell.value
                          ).color,
                        }}
                      >
                        {cell.value.toUpperCase()}
                      </p>
                    </div>
                  </div>
                  {/* {isExpert && cell.value !== "completed" && (
                    <FaChevronDown size={10} />
                  )} */}
                </div>
                {/* {isExpert && cell.value !== "completed" && (
                  <StatusDropdownDeliverable
                    isOpen={isOpen}
                    parentRef={parentRef}
                    setIsOpen={setIsOpen}
                    data={cell.row.original}
                    onSubmit={(value) =>
                      handleStatusUpdate(cell.row.original, value)
                    }
                  />
                )} */}
              </div>
            );
          } else if (cell.column.Header === "Start Date") {
            data = (
              <p className="mr-2 w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                {cell.value?.length > 10
                  ? reverseDateString(cell.value.slice(0, 10))
                  : reverseDateString(cell.value)}
              </p>
            );
          } else if (cell.column.Header === "End Date") {
            data = (
              <p className="mr-2 w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                {cell.value?.length > 10
                  ? reverseDateString(cell.value.slice(0, 10))
                  : reverseDateString(cell.value)}
              </p>
            );
          } else if (cell.column.Header === "Delivery Date") {
            data = (
              <p className="mr-2 w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                {cell.value}
              </p>
            );
          } else if (cell.column.Header === "Action") {
            data = (
              <>
                {isExpert && showModal && (
                  <DeliverableModalForm
                    title={"Sub Deliverable"}
                    data={selectedDeliverable}
                    edit={selectedDeliverable}
                    setOpen={setShowModal}
                    handleSave={hadnleDeliverableSave}
                    loading={delPostLoad}
                    error={delPostErr}
                    setEdit={setSelectedDeliverable}
                  />
                )}
                {view && (
                  <DelViewModal
                    id={"deliverable" + cell.row.original.id}
                    data={row.original}
                    setOpen={setView}
                  />
                )}
                {isExpert ? (
                  <div className="flex items-center gap-3 text-gray-800 dark:text-white">
                    <DeleteModal
                      id={"deliverable" + cell.row.original.id}
                      handleConfirm={() => {
                        handleDelete(cell.row.original.id);
                        window.document
                          .getElementById("deliverable" + cell.row.original.id)
                          .classList.replace("flex", "hidden");
                      }}
                      handleCancel={() => {
                        window.document
                          .getElementById("deliverable" + cell.row.original.id)
                          .classList.replace("flex", "hidden");
                      }}
                    />
                    {cell.row.original.status !== "completed" ? (
                      <button
                        onClick={() => setShowModal(true)}
                        title="Add Sub Deliverable"
                        className="max-w-[100px] truncate rounded-lg border border-brand-500 px-2 py-0.5 text-center text-xs font-[400] hover:bg-brand-500 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white 2xl:max-w-full 4xl:text-sm"
                      >
                        Add Sub Deliverable
                      </button>
                    ) : (
                      <button
                        onClick={() => setView(true)}
                        title="View"
                        className="max-w-[100px] truncate rounded-lg border border-brand-500 px-2 py-0.5 text-center text-xs font-[400] hover:bg-brand-500 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white 2xl:max-w-full 4xl:text-sm"
                      >
                        View Details
                      </button>
                    )}

                    <DropdownMenu>
                      <DropdownMenuTrigger className="outline-none focus:outline-none">
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        {cell.row.original.status !== "completed" && (
                          <>
                            <DropdownMenuItem
                              className="cursor-pointer rounded-md hover:bg-blueSecondary  hover:text-white dark:hover:text-white"
                              onClick={() => {
                                setView(true);
                              }}
                            >
                              View
                            </DropdownMenuItem>

                            <DropdownMenuItem
                              className="cursor-pointer rounded-md hover:bg-blueSecondary  hover:text-white dark:hover:text-white"
                              onClick={() => {
                                setSelected(cell.row.original);
                              }}
                            >
                              Edit
                            </DropdownMenuItem>
                          </>
                        )}
                        <DropdownMenuItem
                          className="cursor-pointer rounded-md hover:bg-blueSecondary  hover:text-white dark:hover:text-white"
                          onClick={() => {
                            window.document
                              .getElementById(
                                "deliverable" + cell.row.original.id
                              )
                              .classList.replace("hidden", "flex");
                          }}
                        >
                          Delete
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                ) : (
                  <div className="flex w-full items-center gap-2">
                    <button
                      className="w-[80%] rounded-lg border border-yellow-500 px-2 py-0.5 text-sm font-[400] hover:bg-yellow-500 hover:text-white dark:hover:bg-yellow-500 dark:hover:text-white"
                      onClick={() => {
                        setView(true);
                      }}
                    >
                      view
                    </button>
                  </div>
                )}
              </>
            );
          }
          return (
            <td
              className="border-b px-0 pt-[14px] pb-[18px] sm:text-[14px]"
              {...cell.getCellProps()}
              key={i}
              style={{
                width: cell.column.width, // Apply custom width
                minWidth: cell.column.minWidth, // Apply min width
                maxWidth: cell.column.maxWidth, // Apply max width
              }}
            >
              {data}
            </td>
          );
        })}
      </tr>
      {/* Deliverables */}
      {showChild &&
        subDeliverables?.map((child, i) => (
          <DeliverableTableRow
            key={child.id}
            child={child}
            link={link}
            i={i}
            isExpert={isExpert}
            handleDelDelete={handleDelDelete}
            setSelected={setSelectedDeliverable}
            handleStatusUpdate={(value) => handleStatusUpdate(child, value)}
          />
        ))}
    </>
  );
}
