import BackIcon from "components/icons/BackIcon";
import { Link, useParams } from "react-router-dom";

function ProjectTitle({ onBackClicked, title, path = "" }) {
  const breadcrumb = path?.split("/").filter((item) => item !== "");
  const urlPath = window.location.pathname;
  const params = useParams();
  const userPath = urlPath.includes("ceo")
    ? "ceo"
    : urlPath.includes("team-leader")
    ? "team-leader"
    : urlPath.includes("ministry")
    ? "ministry"
    : urlPath.includes("higher")
    ? "higher-official"
    : "expert";

  const getUrl = (item) => {
    switch (item) {
      case "tasks":
        return `/${userPath}/tasks/`;
      case "milestones":
        return `/${userPath}/tasks/${params.id}`;
      case "activities":
        return `/${userPath}/tasks/${params.id}/milestones/${params.mileId}`;
      case "deliverables":
        return `/${userPath}/tasks/${params.id}/milestones/${params.mileId}/activities/${params.actId}`;
      case "sub-deliverables":
        return `/${userPath}/tasks/${params.id}/milestones/${params.mileId}/activities/${params.actId}/deliverables/${params.delId}`;
      default:
        return `/${userPath}/tasks/`;
    }
  };

  return (
    <div className="flex gap-8">
      <BackIcon onClick={onBackClicked} className="cursor-pointer" />
      <div className="mt-4 flex items-start gap-3">
        {title && <div className="h-4 w-4 bg-orange-400" />}
        <div>
          <p className="text-3xl font-semibold text-gray-700 dark:text-white ">
            {title}
          </p>
          <p className="mt-2 text-gray-700 dark:text-white ">
            {breadcrumb.map((item, index) => (
              <Link
                key={index}
                to={getUrl(item)}
                className="hover:text-blue-500"
              >
                {isNaN(item) && item}
                {isNaN(item) && index < breadcrumb?.length - 1 && " > "}
              </Link>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
}
export default ProjectTitle;
